import React from "react"
import Layout from "../components/Layout"
import { ContentSubHeading, ContentHeading } from "../components/styles"
import CallToActionPageBottom from "../components/CallToActionPageBottom"
import promotionImage from "../assets/promotion.png"
import bundleImage from "../assets/bundle.png"
import pricingImage from "../assets/pricing.png"
import competitionImage from "../assets/competitiveInsights.png"
import collaborationImage from "../assets/collaboration.png"
import { Fade } from "react-reveal"
import tw from "twin.macro"
import { PrevNextBox } from "./PrevNextBox"
const slug = require("slug")

const H2 = tw.h2`pt-4 pb-2 text-4xl font-black bg-clip-text text-transparent bg-gradient-to-r to-indigo-400 from-indigo-800`
const UL = tw.ul`list-disc list-outside pl-5 text-base text-gray-800 leading-8 max-w-prose mx-auto`
const P = tw.p`max-w-prose py-2 text-gray-600 leading-8`

export default ({
  pageContext: {
    solution: { name, picture, content },
    solutions,
    idx,
  },
}) => {
  const screenshots = new Array()
  screenshots["promotion-planning"] = promotionImage
  screenshots["bundle-planning"] = bundleImage
  screenshots["intelligent-pricing-automation"] = pricingImage
  screenshots["competitive-intelligence"] = competitionImage
  screenshots["go-to-market-collaboration"] = collaborationImage

  const pageSlug = slug(name)

  const prevSolution = solutions[idx - 1] || null
  const nextSolution = solutions[idx + 1] || null

  return (
    <>
      <Layout title={`${name} | Pricelist`} keywords={`Pricelist and ${name} `}>
        <div className="relative px-4 sm:px-6 lg:px-8">
          <div className="mx-auto pb-8 max-w-prose text-lg border-b">
            <h1>
              <ContentSubHeading>Pricelist Solutions</ContentSubHeading>
              <ContentHeading>{name} in Pricelist</ContentHeading>
              {/* <div className="flex items-center justify-center my-8">
                <Link
                  to="/request-demo"
                  className="whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700"
                >
                  Request a demo
                </Link>
              </div> */}
            </h1>
          </div>
          <div className="mx-auto max-w-7xl">
            <div className="align-center flex flex-col mb-16 mt-16 md:flex-row-reverse md:justify-between">
              <div className="w-full md:ml-8 md:w-1/3">
                <H2>{content.heading}</H2>

                <P>{content.description}</P>

                <UL>
                  {content.benefits.map((item, idx) => (
                    <li key={idx}>{item}</li>
                  ))}
                </UL>
              </div>
              <div className="w-full md:w-2/3">
                <Fade>
                  <img
                    src={screenshots[pageSlug]}
                    className="rounded-md shadow-md"
                  />
                  {/* {pageSlug === "go-to-market-collaboration" && (
                    <div className="text-center mt-4 text-indigo-400">
                      See{" "}
                      <Link
                        to="/integrations"
                        className="underline hover:no-underline text-indigo-600"
                      >
                        integrations
                      </Link>{" "}
                      for other ways to integration Pricelist into your
                      collaboration workflow.
                    </div>
                  )} */}
                </Fade>
              </div>
            </div>
            <PrevNextBox next={nextSolution} prev={prevSolution} />
            {/* <div className="flex justify-between w-full text-indigo-700">
              <div className="group w-auto">
                {prevSolution && (
                  <div className="px-4 py-4 bg-gradient-to-l rounded-xl from-indigo-100 to-indigo-50">
                    <div className="text-xs font-semibold group-hover:opacity-30 opacity-50 duration-300">
                      Previous
                    </div>
                    <Link
                      to={`/solutions/${slug(prevSolution.name)}`}
                      className="font-bold group-hover:border-b-2 group-hover:border-indigo-700 duration-300"
                    >
                      « {prevSolution.name}
                    </Link>
                  </div>
                )}
              </div>
              <div className="group w-auto">
                {nextSolution && (
                  <div className="px-4 py-4 bg-gradient-to-r rounded-xl from-indigo-100 to-indigo-50">
                    <div className="text-xs font-semibold group-hover:opacity-30 opacity-50 duration-300">
                      Next
                    </div>
                    <Link
                      to={`/solutions/${slug(nextSolution.name)}`}
                      className="font-bold group-hover:border-b-2 group-hover:border-indigo-700 duration-300"
                    >
                      {nextSolution.name} »
                    </Link>
                  </div>
                )}
              </div>
            </div> */}
          </div>

          <CallToActionPageBottom cta="Get in touch" link="/contact-sales">
            <div>Want to learn how Pricelist solves your challenges?</div>
            <div>Get in touch to find more</div>
          </CallToActionPageBottom>
        </div>
      </Layout>
    </>
  )
}
