import React from "react"
import { Link } from "gatsby"
const slug = require("slug")

export const PrevNextBox = ({ prev, next }) => {
  return (
    <div className="flex flex-col justify-between w-full text-indigo-700 space-y-4 sm:flex-row sm:space-y-0">
      {!prev && <div></div>}
      {prev && <Button content={prev} />}
      {next && <Button content={next} isRight />}
    </div>
  )
}

const Button = ({ content, isRight }) => {
  const { name } = content
  const url = `/solutions/${slug(name)}`
  const direction = isRight ? "Next" : "Previous"

  return (
    <div className="group mx-8 w-auto">
      <div
        className={`px-4 py-4 w-72 bg-gradient-to-l rounded-md from-indigo-100 to-indigo-50 ${
          isRight ? "text-right" : ""
        }`}
      >
        <div className="text-xs font-semibold group-hover:opacity-30 opacity-50 duration-300">
          {direction}
        </div>
        <Link
          to={url}
          className="font-bold group-hover:border-b-2 group-hover:border-indigo-700 duration-300"
        >
          {!isRight && <>« </>}
          {name}
          {isRight && <> »</>}
        </Link>
      </div>
    </div>
  )
}
